import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./plugins/element.js";

import md5 from "js-md5";

import "@/permission"; // permission control

import "@/style/index.less";

Vue.config.productionTip = false;

Vue.prototype.$md5 = md5;

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount("#app");
