import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
    {
        path: "/",
        name: "Index",
        component: () => import("@/views/index/index"),
        meta: {
            title: "抖快手在线去水印，美拍小红书去水印-短视频去水印"
        }
    }
];

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes
});

export default router;
